$blue: #3547FD;
$black: #000000;
$white: #ffffff;

$text-color: #403845; // Light Charcoal
$text-secondary: #605866; // text gray
$default-radius: 8px;
$big-radius: 10px;
$circle-radius: 50%;

$font-regular: 'Gilroy';
$font-medium: 'Gilroy';
$font-bold: 'Gilroy';

$h1-font-size: 72px;
$h1-line-height: 88px;

$h2-font-size: 48px;
$h2-line-height: 60px;

$h3-font-size: 28px;
$h3-line-height: 32px;

$h4-font-size: 18px;
$h4-line-height: 24px;

$p-font-size: 16px;
$p-line-height: 24px;

$small-1-font-size: 14px;
$small-1-line-height: 21px;

$small-2-font-size: 12px;
$small-2-line-height: 18px;


