@import '../core/variables';

@media (max-width: 576px) {
  .sliderNavigation {
    top: -29px;
    right: -7px;
    position: absolute;


    .sliderPrev {
      cursor: pointer;
      display: block;
      float: left;
      background: url("../../images-local/icon/arrow-left-mobile.svg") no-repeat center;
      width: 47px;
      height: 30px;
      padding: 7px;
    }

    .sliderNext {
      cursor: pointer;
      display: block;
      float: left;
      width: 47px;
      height: 30px;
      background: url("../../images-local/icon/arrow-right-mobile.svg") no-repeat center;
      padding: 7px;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .sliderNavigation {
    top: 7px;
    right: -7px;
    position: absolute;


    .sliderPrev {
      cursor: pointer;
      display: block;
      float: left;
      background: url("../../images-local/icon/arrow-left-mobile.svg") no-repeat center;
      width: 47px;
      height: 30px;
      padding: 7px;
    }

    .sliderNext {
      cursor: pointer;
      display: block;
      float: left;
      width: 47px;
      height: 30px;
      background: url("../../images-local/icon/arrow-right-mobile.svg") no-repeat center;
      padding: 7px;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .sliderNavigation {
    position: absolute;
    top: -45px;
    right: 2px;

    .sliderPrev {
      cursor: pointer;
      display: block;
      float: left;
      width: 68px;
      height: 56px;
      background: #F4F5FF url("../../images-local/icon/arrow-left.svg") no-repeat center;
      text-align: center;
    }

    .sliderNext {
      cursor: pointer;
      display: block;
      float: right;
      width: 68px;
      height: 56px;
      background: #606EFF url("../../images-local/icon/arrow-right.svg") no-repeat center;
      text-align: center;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}


@media (max-width: 992px) {

}