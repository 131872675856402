@import '../../core/variables';


@media (max-width: 576px) {
  .newPostsContainer {
    .title {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
      color: #000000;
      margin-bottom: 24px;
      display: block;
      margin-top: 30px;
    }
  }


  .mostReadContainer {
    margin-top: 31px;
    padding-left: 39px;

    .titleh3 {
      display: inline-block;
      padding-bottom: 30px;
      font-family: $font-regular;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
    }
  }

  .topTagsContainer {
    margin-top: 31px;
    padding-left: 39px;

    .titleh3 {
      display: inline-block;
      padding-bottom: 30px;
      font-family: $font-regular;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
    }

    a {
      display: inline-block;
      justify-content: center;
      align-items: center;
      padding: 11px 16px;
      border: 1px solid #F1EBF5;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #2F2A32;
      margin: 0 16px 16px 0;
    }
  }


  .pageDescription {
    margin-top: 50px;
    margin-bottom: 70px;

    h1 {
      margin-top: 24px;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      color: #605866;
    }

    div {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #605866;
      margin-top: 25px;
    }

    a {
      margin-top: 35px;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 800;
      font-size: 15px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      display: inline-block;
      color: #3547FD;
    }
  }

  .topTagsContainer {
    display: none;
  }

  .twoColumnsContainer {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0;
  }

  .mostReadContainer {
    padding: 0;
    margin-top: 0;
  }

  .topPostsContainer {
    margin-top: 25px;
    padding: 2px 0 0;
  }

  .topPostsSlider {
    padding: 24px 0 0;
    position: relative;
  }

  .newPostsList {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 24px;
    padding-top: 33px;
    padding-bottom: 30px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .newPostsContainer {
    margin-top: 35px;

    .title {
      font-family: Gilroy;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
      color: #000000;
      margin-bottom: 24px;
      display: block;
      margin-top: 30px;
    }
  }
  .mostReadContainer {
    margin-top: 31px;
    padding-left: 39px;

    .titleh3 {
      display: inline-block;
      padding-bottom: 30px;
      font-family: $font-regular;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
    }
  }

  .topTagsContainer {
    margin-top: 31px;
    padding-left: 39px;

    .titleh3 {
      display: inline-block;
      padding-bottom: 30px;
      font-family: $font-regular;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 32px;
    }

    a {
      display: inline-block;
      justify-content: center;
      align-items: center;
      padding: 11px 16px;
      border: 1px solid #F1EBF5;
      box-sizing: border-box;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.01em;
      color: #2F2A32;
      margin: 0 16px 16px 0;
    }
  }


  .pageDescription {
    margin-top: 50px;
    margin-bottom: 70px;

    h1 {
      margin-top: 24px;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      color: #605866;
    }

    div {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #605866;
      margin-top: 25px;
    }

    a {
      margin-top: 35px;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 800;
      font-size: 15px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      display: inline-block;
      color: #3547FD;
    }
  }

  .topTagsContainer {
    display: none;
  }

  .twoColumnsContainer {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0;
  }

  .mostReadContainer {
    padding: 0;
    margin-top: 0;
  }

  .topPostsSlider {
    padding: 24px 0 0;
    position: relative;
  }

  .newPostsList {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 24px;
    padding-top: 33px;
    padding-bottom: 30px;
  }
  .topPostsContainer {
    margin-top: 30px;
    padding: 2px 0 0;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .topPostsContainer {
    margin: 40px 0 0;
    position: relative;
  }
  .newPostsList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .newPostsContainer {
    .title {
      font-style: normal;
      font-weight: 800;
      font-size: 26px;
      line-height: 36px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .newPostsContainer {
    .title {
      font-style: normal;
      font-weight: 800;
      font-size: 48px;
      line-height: 60px;
      color: #000000;
    }
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .newPostsContainer {
    margin-top: 0;
  }
  .topTagsContainer {
    display: block;
  }
  .newPostsList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32px;
    padding-top: 33px;
    padding-bottom: 30px;
  }

  .topPostsContainer {
    margin: 70px 0 0;
    position: relative;
  }

  .topPostsSlider {
    padding: 33px 0 0;
    position: relative;
  }

  .twoColumnsContainer {
    display: grid;
    grid-template-columns: minmax(700px, 80%) 320px;
    grid-column-gap: 28px;
    margin-top: 3px;
  }

  .mostReadContainer {
    margin-top: 31px;
    padding-left: 39px;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .twoColumnsContainer {
    display: grid;
    grid-template-columns: minmax(700px, 80%) 405px;
    grid-column-gap: 28px;
    margin-top: 3px;
  }
}