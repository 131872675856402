@import '../../core/variables';



@media (max-width: 576px) {
  .postCategory {
    span {
      font-size: 32px
    }

    a {
      font-family: $font-regular;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #605866;
    }

    .dot {
      width: 4px;
      height: 4px;
      display: inline-block;
      border-radius: 2px;
      margin: 0 8px 2px 0;
    }
  }

  .itemPreview {
    box-shadow: 8px 8px 16px rgba(153, 153, 153, 0.12);
    height: 100%;
    max-width: 405px;
    position: relative;
  }

  .itemImage {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    height: 204px;
    overflow: hidden;

    a {
      display: inline-block;
      line-height: 0;

      > span {
        line-height: 0;
        display: inline-block;
      }
    }

    img {
      width: 405px;
      height: 287px;
    }
  }

  .itemCategory {
    font-family: $font-regular;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #605866;
  }

  .itemDetails {
    padding: 22px 24px 24px 24px;
  }

  .itemName {
    margin-top: 16px;
    max-height: 50px;
    overflow: hidden;

    > a {
      font-family: $font-regular;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #403845;
    }
  }

  .itemDescription {
    font-family: $font-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #605866;
    max-height: 42px;
    overflow: hidden;
    margin-top: 7px;
  }

  .blocked {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgb(255,255,255, 0.8);
    top: 0;
    left: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    .blockDescr {
      text-align: center;
      img {
        display: inline-block;
        width: 32px;
      }
      span {
        margin-top: 16px;
        display: block;
        font-family: Gilroy;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #3547FD;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .postCategory {
    span {
      font-size: 32px
    }

    a {
      font-family: $font-regular;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #605866;
    }

    .dot {
      width: 4px;
      height: 4px;
      display: inline-block;
      border-radius: 2px;
      margin: 0 8px 2px 0;
    }
  }

  .itemPreview {
    box-shadow: 8px 8px 16px rgba(153, 153, 153, 0.12);
    height: 100%;
    max-width: 405px;
    position: relative;
  }

  .blocked {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgb(255,255,255, 0.8);
    top: 0;
    left: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    .blockDescr {
      text-align: center;
      img {
        display: inline-block;
        width: 32px;
      }
      span {
        margin-top: 16px;
        display: block;
        font-family: Gilroy;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #3547FD;
      }
    }
  }

  .itemImage {
    text-align: center;
    margin: 0 auto;
    width: 100%;
    height: 204px;
    overflow: hidden;

    a {
      display: inline-block;
      line-height: 0;

      > span {
        line-height: 0;
        display: inline-block;
      }
    }

    img {
      width: 405px;
      height: 245px;
    }
  }

  .itemCategory {
    font-family: $font-regular;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #605866;
  }

  .itemDetails {
    padding: 22px 24px 24px 24px;
  }

  .itemName {
    margin-top: 16px;
    max-height: 50px;
    overflow: hidden;

    > a {
      font-family: $font-regular;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #403845;
    }
  }

  .itemDescription {
    font-family: $font-regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #605866;
    max-height: 42px;
    overflow: hidden;
    margin-top: 7px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .itemImage {
    height: 245px;
    overflow: hidden;

    img {
      width: 405px;
      height: 245px;
    }
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .itemImage {
    height: 204px;
    overflow: hidden;

    img {
      width: 405px;
      height: 204px;
    }
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .itemImage {
    height: 245px;
    overflow: hidden;

    img {
      width: 405px;
      height: 245px;
    }
  }
}