@import '../../core/variables';

@media (max-width: 576px) {
  .gridList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 405px));
    grid-gap: 24px;
    padding-bottom: 30px;
    place-items: center;
  }

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .gridList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 405px));
    place-items: center;
    grid-gap: 24px;
    padding-bottom: 30px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .gridList {
    grid-template-columns: repeat(auto-fill, minmax(280px, 348px));
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .gridList {
    grid-template-columns: repeat(auto-fit, 405px);
    grid-gap: 32px;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .gridList {
    grid-template-columns: repeat(auto-fit, 405px);
    grid-gap: 24px;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
}
